<template>

    <div class="invoice" id="div1" style="display: block">
      <van-dropdown-menu >
        <van-dropdown-item  v-model="value1" :options="timeList" @change="getTime" />
        <van-dropdown-item v-model="value2" :options="statusList" @change="getStatus" />
      </van-dropdown-menu>
      <div style="height: 12px;"></div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          offset="10"
          @load="onLoad"
      >
        <van-cell-group  v-for="item in list" :key="item.id" class="invoice-list">
          <van-cell :to="'/tax/add?id='+item.id">
            <van-row>
              <van-col span="20" class="invoice-title">开票申请编号：{{ item.order_no }}</van-col>
              <!-- <van-col span="4" style="text-align: right">
                <van-tag plain type="primary" v-if="item.status===1">待提交</van-tag>
                <van-tag plain type="warning" v-if="item.status===2">待支付</van-tag>
                <van-tag plain type="danger" v-if="item.status===4||(item.status===6&&!item.pick_up)">待开票</van-tag>
                <van-tag plain type="success" v-if="item.status===6&&item.pick_up">已开票</van-tag>
                <van-tag plain type="default" v-if="item.status===7">已作废</van-tag>
                <van-tag plain color="#333333" v-if="item.status===8 && item.pick_up">已领取</van-tag>
              </van-col> -->
              <van-col span="4" style="text-align: right">
                <van-tag plain type="primary" v-if="item.status===1">待提交</van-tag>
                <van-tag plain type="warning" v-if="item.status===2">待支付</van-tag>
                <van-tag plain type="danger" v-if="item.status===4">待开票</van-tag>
                <van-tag plain type="success" v-if="item.status===6">已开票</van-tag>
                <van-tag plain type="default" v-if="item.status===7">已作废</van-tag>
                <van-tag plain color="#333333" v-if="item.status===8">已领取</van-tag>
              </van-col>
            </van-row>
          </van-cell>
          <van-cell class="invoice-detail" :to="'/tax/add?id='+item.id">
            <van-col span="18" class="invoice-desc">
              <span class="font-color">含税总金额(￥)：</span>
              {{item.tax_amount}}
            </van-col>
            <van-col span="18" class="invoice-desc">
              <span class="font-color">税费(￥)：</span>
              {{item.total_tax}}
            </van-col>
            <van-col span="18" class="invoice-desc">
              <span class="font-color">发票号码：</span> <span v-if="item.status>=6&&item.pick_up">{{ item.invoice_no }}</span>
            </van-col>
          </van-cell>
          <!-- <van-row v-if="item.status===6&&item.pick_up"> -->
          <van-row v-if="item.status===6">
            <van-col span="17"></van-col>
            <van-col span="7" style="height:1.1rem;padding-top: 5px;padding-left: 4px;">
              <van-button style="border-radius: 4px;border: unset;width: 2.4rem;height: 0.8rem;line-height: 0.8rem;background-color: #ff552e" block type="warning" @click="confirmStatus(item.id)" >
                我已领票
              </van-button>
            </van-col>
          </van-row>

          <div class="invoice-list-group">
            <van-button round color="linear-gradient(to right, #ff6034, #ff6034)" @click="addBtn" size="normal">添加</van-button>
          </div>
        </van-cell-group>
      </van-list>
      </van-pull-refresh>
    </div>
    <!-- nodata -->
    <div class="no-data" id="div2" style="display: none">
      <van-dropdown-menu >
        <van-dropdown-item  v-model="value1" :options="timeList" @change="getTime" />
        <van-dropdown-item v-model="value2" :options="statusList" @change="getStatus" />
      </van-dropdown-menu>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list >
          <van-empty description="" class="invoice-empty">
            <van-button style="border-radius: 4px;" type="danger" class="bottom-button" size="normal" @click="addBtn">添加发票申请</van-button>
          </van-empty>
        </van-list>
      </van-pull-refresh>
    </div>
<!--    <div class="invoice-list-group-logout">-->
<!--&lt;!&ndash;      color="linear-gradient(to right, #999, #ccc)"&ndash;&gt;-->
<!--      <van-button round color="#CCCCCC" size="normal" :to="{path:'/user/index'}">账号</van-button>-->
<!--    </div>-->

</template>

<script>
import {reactive,  toRefs} from "vue";
import {Toast} from "vant";
import {Invoice, Pick} from "@/api/invoice";
import {getMerchantDetail} from "@/api/merchant";
import {useRouter} from "vue-router";

export default {
  setup() {
    const route = useRouter();
    const state = reactive({
      receive_list: [],
      list: [],
      loading: false,
      finished: false,
      // show_receive: false,
      page: 1,
      page_size: 10,
      total:0,
      isLoading: false,
      add_btn:false,
      value1: 2,
      value2: 20,
      time_type:2,
      status:20,
      invoice_id:'',
      invoice_time:'',
      invoice_no:'',
      tax_no:'',
    });
    const loadData = ()=> {
      var div1= document.getElementById("div1");
      var div2= document.getElementById("div2");
      Invoice({
        type:'page',
        page:state.page,
        page_size:state.page_size,
        time_type:state.time_type,
        status:state.status
      }).then(function (res) {
        if (res.Code == 200) {
          const resp = res.Data;
              // console.log(resp);
          if (resp.data.length === 0) {  		// 判断获取数据条数若等于0
            state.finished = true;		// 停止加载
            if(state.page ===1 && resp.data.length ===0){
              state.list = [];				// 清空数组
            }
          }else {
            // 若数据条数不等于0
            state.total = resp.total;		// 给数据条数赋值
            state.list.push(...resp.data)	// 将数据放入list中
            state.loading = false;			// 加载状态结束
            // 如果list长度大于等于总数据条数,数据全部加载完成
            if (state.list.length >= state.total) {
              state.finished = true;		// 结束加载状态
            }
          }

          if(state.list.length === 0){
            div1.style.display='none';
            div2.style.display='block';
          }else{
            div1.style.display='block';
            div2.style.display='none';
          }
          state.page++;
          var arr = [1,2];
          if(state.list.length>0 && arr.indexOf(state.list[0].status)>-1){
            state.add_btn = true;
          }
        }
      }).catch(function (err) {
          console.info(err)
      })
    }
    const onLoad = () => {
      loadData();
    };
    const onRefresh = ()=> {
      setTimeout(() => {
        state.isLoading = false;
        state.page=1;
        state.list = [];
        // 将 loading 设置为 true，表示处于加载状态
        state.loading = true;
        // 清空列表数据
        state.finished = false;
        state.add_btn = false;
        loadData();
      }, 1000);
    };
    const addBtn = ()=> {
      getMerchantDetail().then(function (res){
        if(res.Code===200){
          const info = res.Data;
          if(info.state !== 1){
            Toast({
              message: '您没有开票权限,请先联系市场工作人员。',
            });
            return false;
          }
          //一键禁止开票
          if(info.is_stop == 2){
            Toast({
              message: info.stop_remark,
            });
            return false;
          }
          if(state.add_btn==true){
            Toast({
              message: '您还有待处理的申请单，请先处理完成。',
            });
          }else{
            route.push({
              path:'/tax/add',
              query:{id:0}
            })
          }
        }
      }).catch(function (err){
        console.info(err)
      })
    };
    const timeList = [
      { text: '近七天', value: 1 },
      { text: '一个月', value: 2 },
      { text: '三个月', value: 3 },
      { text: '全部', value: 4 },
    ];
    const statusList = [
      { text: '全部', value: 20 },
      { text: '待提交', value: 1 },
      { text: '待支付', value: 2 },
      { text: '待开票', value: 4 },
      { text: '已开票', value: 6 },
      { text: '已作废', value: 7 },
      { text: '已领取', value: 8 },
    ];
    const getTime = (value) =>{
      state.list = [];
      state.page = 1;
      state.time_type = value;
      setTimeout(() => {
        state.isLoading = false;
        state.page=1;
        state.list = [];
        // 将 loading 设置为 true，表示处于加载状态
        state.loading = true;
        // 清空列表数据
        state.finished = false;
        state.add_btn = false;
        loadData();
      }, 1000);

    };
    const getStatus = (value) =>{
      state.list = [];
      state.page = 1;
      state.status = value;
      setTimeout(() => {
        state.isLoading = false;
        state.page=1;
        state.list = [];
        // 将 loading 设置为 true，表示处于加载状态
        state.loading = true;
        // 清空列表数据
        state.finished = false;
        state.add_btn = false;
        loadData();
      }, 1000);
    };
    const confirmStatus = async(value) =>{
      var myDate = new Date();
      var hour = myDate.getHours();
      if(hour < 9 || hour > 14){
        Toast({
          message: '请在每天9:00-13:59领票时间内领取发票。',
        });
        return false;
      }
      let id = value;
      Pick({id:id}).then(function (res){
        if(res.Code==200){
          Toast({
            type: "success",
            message: res.Msg,
            duration: 1 * 1000,
          });
          setTimeout(() => {
            state.isLoading = false;
            state.page=1;
            state.list = [];
            // 将 loading 设置为 true，表示处于加载状态
            state.loading = true;
            // 清空列表数据
            state.finished = false;
            state.add_btn = false;
            loadData();
          }, 1000);
        }
      }).catch(function (err){
        console.info(err)
      })
    };
    return {
      ...toRefs(state),
      onLoad,
      onRefresh,
      addBtn,
      timeList,
      statusList,
      getTime,
      getStatus,
      // onPickUp,
      // cnacelStatus,
      confirmStatus,
    };
  },
  created() {
    let open_id = this.$route.query.open_id;
    // localStorage.removeItem('open_id');
    if(open_id){
      localStorage.setItem('open_id',open_id)
    }
    // 刷新 merchant info
    getMerchantDetail().then(function (res){
      if(res.Code===200){
        const info = res.Data;
        //持久化
        localStorage.setItem('user',JSON.stringify(info))
      }
    }).catch(function (err){
      console.info(err)
    })

  },
  mounted() {
    let open_id = localStorage.getItem('open_id');
    let user = JSON.parse(localStorage.getItem('user'));
    if(!open_id){
      window.location.href = '/wechat/user?state='+user.id;
    }
  }
};
</script>

<style lang="less" scoped>
.van-tag{
  line-height: 20px;
}
.van-dropdown-menu>.van-dropdown-menu__item{
  border: #bdb9b1 1px solid;
}
.van-button--danger{
  background-color: #ff552e;
}
.no-data{
  background: #f6f6f6;
  :deep(.invoice-empty){
    height: auto;
    min-height: 100vh;
    background: #fff;
  }
}
.invoice {
  height: auto;
  min-height: 100vh;
  background: #f6f6f6;
  padding-bottom: 60px;
  :deep(&-list){
    margin-bottom: 10px;
  }
  :deep(&-title){
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  :deep(&-desc){
    height: 25px;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  :deep(.font-color){
    color:#8c939d;
  }
}
.bottom-button {
  width: 160px;
  height: 40px;
}
.invoice-list-group{
  right: 22px;
  bottom: 13%;
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 30px;
  cursor: pointer;
}
.invoice-list-group>button{
  height: 49px;
  width: 49px;
  border-radius: 50%;
}
//退出
.invoice-list-group-logout{
  right: 22px;
  bottom: 10%;
  position: fixed;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-radius: 30px;
  cursor: pointer;
}
.invoice-list-group-logout>button{
  height: 49px;
  width: 49px;
  font-size: 12px;
  border-radius: 50%;
}
.invoice-list-group-logout,.invoice-list-group{
  :deep(.van-button__content) {
    word-break: keep-all;
    white-space: nowrap;
  }
}

.van-row{
  width: 100%;
}
.van-dropdown-menu{
  :deep(.van-dropdown-menu__title) {
    font-size: 0.37333rem;
  }
}
</style>
